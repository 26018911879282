<template>
  <div class="panel panel--profile">
    <div v-if="hide === true && showLastOnlineStatus === true" class="last-online">{{ $t('last-online') }} {{ profile.last_online_at | timeFromNow() }}</div>
    <div class="panel-heading pb-1">
      <h3>{{ $t("profile") }}</h3>
      <a v-if="editable" @click.prevent="openProfileSidebar" href="#">
        <EditIcon2 class="btn-edit" />
      </a>
      <div v-if="hide === true" class="action-icons-row grid-icons hidden-print">
        <div class="row-item a">
          <a @click.prevent="sendingAnAssignment(profile.uuid)" href="#" v-tooltip.hover="$t('contact')"><SearchEnvelopeIcon /></a>
        </div>
        <div class="row-item c">
          <a @click.prevent="addToShortlist(profile.uuid)" v-tooltip.hover="$t('add')" href="#"><AddToIcon /></a>
        </div>
      </div>
    </div>
    <div class="panel-body">
      <div class="profile__top">
        <div class="profile__top__avatar" v-bind:style="{ 'background-color': profile.anonymous_color }">
          <img v-if="profile && profile.main_image && !notPublic" :src="profile.main_image" alt="" />
          <span v-else-if="profile && profile.name && !profile.main_image && !hide">
            {{ userInitials(profile) }}
          </span>
          <span v-else v-bind:style="{ 'background-color': profile.anonymous_color }">
            <SearchAvatarIcon />
          </span>
        </div>
        <div class="profile__top__info">
          <h4 v-if="!hide || ( hide && $route.name === 'neoworqer-profile' && profile.name )" class="text-primary">{{ profile.name }}</h4>
          <h4 v-else class="text-primary">{{ profile.userActiveResume.job_title }}</h4>
          <p v-if="hide && $route.name === 'neoworqer-profile' && profile.name">{{ profile.userActiveResume.job_title }}</p>
          <p>{{ profile.address }}</p>
          <small v-if="profile.userActiveResume.timezone">{{ localTime }} {{ $t('local-time') }}</small>
        </div>
      </div>
      <div class="profile__body">
        <ul class="list-group profile__list-group">
          <li class="list-group-item list-group-item-default">
            <p>
              <RatingIcon class="rating-icon" /> <span>{{ $t('overall-rating') }}:</span>
            </p>
            <strong v-if="profile.ratings">{{ profile.ratings }}/5</strong>
            <strong v-else>{{'N/A'}}</strong>
          </li>
          <li class="list-group-item list-group-item-primary">
            <p><ClockIcon class="clock-icon" /> <span>{{ $t('hourly-rate') }}:</span></p>
            <strong v-if="profile.userDetails && profile.userDetails.hourly_rate">{{this.$appConfig.currency}} {{ profile.userDetails.hourly_rate | formatNumber }}/h</strong>
            <strong v-else>{{this.$appConfig.currency}} {{ profile.userDetails.hourly_rate | formatNumber }}/h</strong>
          </li>
        </ul>
      </div>
      <div v-if="!hide" class="profile__footer">
        <p>
          <span>{{ $t('current-pension') }} ({{this.$appConfig.currency}}):</span>
          <strong v-if="profile.userDetails && profile.userDetails.current_pension">{{ profile.userDetails.current_pension | formatNumber }}</strong>
          <strong v-else>-</strong>
        </p>
        <!-- <p>
          <span>{{ $t('current-3a') }} ({{this.$appConfig.currency}}):</span>
          <strong v-if="profile.userDetails && profile.userDetails.current_3a">{{ profile.userDetails.current_3a }}</strong>
          <strong v-else>-</strong>
        </p>
        <p>
          <span>{{ $t('fixed-cost-per-year') }}:</span>
          <strong v-if="profile.userDetails && profile.userDetails.fixed_cost_year">{{ profile.userDetails.fixed_cost_year }}</strong>
          <strong v-else>-</strong>
        </p> -->
        <p>
          <span>{{ $t('current-neoworq-credit') }} ({{this.$appConfig.currency}}):</span>
          <strong v-if="profile.userDetails && profile.userDetails.current_credit">{{ profile.userDetails.current_credit | formatNumber }}</strong>
          <strong v-else>-</strong>
        </p>
        <p>
          <span class="text-gray">{{ $t('financial-data-last-update') }}:</span>
          <strong >{{ profile.userDetails.updated_at | date('DD MMM YYYY', 'YYYY-MM-DD') }}</strong>
        </p>
      </div>
      <router-link
        v-if="!hide"
        :to="{ path: `/public-resume/${profile.uuid}` }"
        class="btn btn-block btn-primary-light-outline"
      >
        {{ $t("see-public-view") }}
      </router-link>
      <button v-else class="btn btn-block btn-primary-light-outline hidden-print" @click.prevent="generateReport">{{ $t('download-cv') }}</button>
      <Alert v-if="$route.name === 'public-resume'" type="warning" class="alert w-100 text-center mt-2 mb-0"><small>{{ $t('this-is-your-public-anonymized-profile') }}</small></Alert>
    </div>
    <div class="pending" v-if="condition === 'pending'">
      <DangerIcon/>
      <p>{{$t("changes")}}</p>
    </div>
    <div v-show="false">
      <ResumePDF ref="download" :profile="profile"/>
    </div>
  </div>
</template>

<script>
import { Alert, tooltip } from 'uiv';
import moment from 'moment-timezone';
import EditIcon2 from '../icons/EditIcon2';
import RatingIcon from '../icons/RatingIcon';
import ClockIcon from '../icons/ClockIcon';
import DangerIcon from '../icons/DangerIcon';
import SearchAvatarIcon from '../icons/SearchAvatarIcon';
import ResumePDF from './ResumePDF';
import { timeFromNow, formatDate } from '@/utils/date.filter';
import { formatNumber } from '@/utils/number.filter';
import AddToIcon from '@/components/icons/AddToIcon.vue';
import SearchEnvelopeIcon from '@/components/icons/SearchEnvelopeIcon.vue';

export default {
  name: 'profile-panel',
  i18nOptions: { namespaces: 'profile-panel' },
  components: {
    SearchEnvelopeIcon,
    AddToIcon,
    Alert,
    EditIcon2,
    RatingIcon,
    ClockIcon,
    DangerIcon,
    SearchAvatarIcon,
    ResumePDF,
  },
  directives: {
    tooltip,
  },
  data() {
    return {
      localTime: null,
      showLastOnlineStatus: false,
    };
  },
  props: ['profile', 'condition', 'editable', 'notPublic'],
  filters: {
    timeFromNow: timeFromNow,
    date: formatDate,
    formatNumber: formatNumber,
  },
  mounted() {
    this.timezone();
    this.shouldShowLastOnlineStatus();
  },
  computed: {
    hide() {
      return this.$route.name !== 'resume'
             && this.$route.name !== 'overview'
             && this.$route.name !== 'overview-notification';
    },
  },
  methods: {
    timezone() {
      setInterval(() => {
        this.localTime = moment.tz(this.profile.userActiveResume.timezone).format('h:mm a');
      }, 1000);
    },
    userInitials(user) {
      if (user && user.name !== '') {
        const name = user.name.split(' ');
        return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`;
      }
    },
    openProfileSidebar() {
      this.$store.commit('sidebar/isProfileSidebarOpen', true);
    },
    generateReport() {
      window.print();
      // this.$refs.download.generateReport();
    },
    sendingAnAssignment(neoworerId) {
      this.$store.commit('assignment/assignmentProps', { neoworker_uuid: neoworerId });
      this.$router.push('/sending-an-assignment');
    },
    addToShortlist(neoworerId) {
      this.$store.commit('shortlist/shortlistDataProps', { neoworker_uuid: neoworerId });

      this.$store.commit('templates/templateProps', { neoworker_uuid: neoworerId });
      this.$router.push('/save-to-shortlist');
    },

    shouldShowLastOnlineStatus() {
      // if profile.last_online_at is from more than month, then it should be false
      const lastOnline = moment(this.profile.last_online_at);
      const now = moment();
      const diff = now.diff(lastOnline, 'months');
      this.showLastOnlineStatus = diff < 1;
      console.log('diff', diff);
    },

  },
};
</script>
