<template>
  <div>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :manual-pagination="false"
      filename="Resume"
      pdf-format="a4"
      :paginate-elements-by-height="1800"
      :pdf-quality="2"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :html-to-pdf-options="htmlToPdfOptions"
      @progress="onProgress($event)"
      @startPagination="startPagination()"
      @hasPaginated="hasPaginated()"
      @beforeDownload="beforeDownload($event)"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="pdf-content" @domRendered="domRendered()">
        <div class="heading">
          <h1 v-if="profile.userActiveResume">{{ profile.userActiveResume.job_title }}</h1>
          <small v-if="profile.userActiveResume && profile.userActiveResume.timezone">{{
            profile.userActiveResume.timezone
          }}</small>
          <small
            v-if="profile.userActiveResume && profile.userActiveResume.address"
            v-html="profile.userActiveResume.address"
          ></small>
        </div>
        <br />
        <div>
          <p v-if="profile.userDetails && profile.userDetails.hourly_rate">
            {{$t('hourly-rate')}}: <strong>{{ profile.userDetails.hourly_rate }} {{ currency }}</strong>
          </p>
          <p v-if="profile.ratings">
            {{$t('overall-rating')}}: <strong>{{ profile.ratings }} / 5</strong>
          </p>
        </div>
        <div class="section">
          <div class="header">
            <h2>{{$t('skills')}}</h2>
          </div>
          <div class="body">
            <div v-if="profile.skills">
              <ul :key="skill.id" v-for="skill in profile.skills">
                <li class="skill" :class="{ 'skill--blue': skill.active_for_neoworqer }">
                  {{ skill.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="section" v-if="profile.userActiveResume && profile.userActiveResume.bio">
          <div class="header">
            <h2>{{$t('bio')}}</h2>
          </div>
          <div class="body">
            <div v-html="profile.userActiveResume.bio"></div>
          </div>
        </div>
        <div class="section" v-if="profile.userActiveResume && profile.userActiveResume.education">
          <div class="header">
            <h2>{{$t('education')}}</h2>
          </div>
          <div class="body">
            <div v-html="profile.userActiveResume.education"></div>
          </div>
        </div>
        <div class="section" v-if="profile.userActiveResume && profile.userActiveResume.languages">
          <div class="header">
            <h2>{{$t('languages')}}</h2>
          </div>
          <div class="body">
            <div v-html="profile.userActiveResume.languages"></div>
          </div>
        </div>
        <div class="section" v-if="profile.userActiveResume && profile.userActiveResume.experience">
          <div class="header">
            <h2>{{$t('experience')}}</h2>
          </div>
          <div class="body">
            <div v-html="profile.userActiveResume.experience"></div>
          </div>
        </div>
        <div v-if="profile.userActiveResume && profile.userActiveResume.work_experiences" class="section">
          <div class="header">
            <h2>{{$t('work-history')}}</h2>
          </div>
          <div class="body">
            <ul>
              <li v-for="experience in profile.userActiveResume.work_experiences" :key="experience.id">
                  <div class="work-item">
                    <strong>{{ $t('position') }}:</strong>
                      <p>{{ experience.position }}</p>
                  </div>
                  <div class="work-item">
                    <strong>{{ $t('period') }}:</strong>
                      <p>{{ $t('from') }} {{ experience.from | date('DD MMM YYYY', 'YYYY-MM-DD') }} {{ $t('to') }} {{ experience.to | date('DD MMM YYYY', 'YYYY-MM-DD') }}</p>
                  </div>
                  <div class="work-item">
                    <strong>{{ $t("details") }}:</strong>
                    <div v-html="experience.details"></div>
                  </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import { formatDate } from '../../utils/date.filter';

export default {
  name: 'resume-pdf',
  i18nOptions: { namespaces: 'resume-panel' },
  components: {
    VueHtml2pdf,
  },
  props: ['profile'],
  data() {
    return {
      currency: this.$appConfig.currency,
      contentRendered: false,
      progress: 0,
      generatingPdf: false,
      pdfDownloaded: false,
    };
  },
  filters: {
    date: formatDate,
  },
  computed: {
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: 'resume.pdf',
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: 'letter',
          orientation: 'portrait',
        },
      };
    },
  },
  methods: {
    async generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress(progress) {
      this.progress = progress;
      // console.log(`PDF generation progress: ${progress}%`);
    },
    startPagination() {
      // console.log('PDF has started pagination');
    },
    hasPaginated() {
      // console.log('PDF has been paginated');
    },
    hasDownloaded(blobPdf) {
      // console.log('PDF has downloaded yehey');
      this.pdfDownloaded = true;
      // console.log(blobPdf);
    },
    async beforeDownload() {
      // console.log('On Before PDF Generation');
    },
    domRendered() {
      // console.log('Dom Has Rendered');
      this.contentRendered = true;
    },
    onBlobGenerate(blob) {
      // console.log(blob);
    },
  },
};
</script>
