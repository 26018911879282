import { MessageBox } from 'uiv';

class ModalService {
  alert(options) {
    return MessageBox.alert({ ...this.defaultOptions, ...options });
  }

  prompt(options) {
    return MessageBox.prompt({ ...this.defaultOptions, ...options });
  }

  confirm(options) {
    return MessageBox.confirm({ ...this.defaultOptions, ...options });
  }
}

export default new ModalService();
